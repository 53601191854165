import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  setValue(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getValue(key: string): string {
    return localStorage.getItem(key);
  }

  setObject(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getObject(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  removeValue(key: string) {
    localStorage.removeItem(key);
  }

}
