import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthorizeService } from '../api/authorize.service'
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileGuard implements CanActivate {
  constructor(private authService: AuthorizeService, private router: Router, private cookieService: CookieService) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const currentUser = this.authService.getUserInfo();
      const profile_saved = this.cookieService.getObject('profile');
      const region_saved = this.cookieService.getObject('region');
      if (currentUser.isThirdPartyLogin 
            && (!profile_saved || !profile_saved.firstName || !region_saved || !region_saved.id)) {
        this.router.navigate(['/profiles']);
        return false;
      } else {
        return true;
      }
  }

  canLoad(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const currentUser = this.authService.getUserInfo();
      const profile_saved = this.cookieService.getObject('profile');
      const region_saved = this.cookieService.getObject('region');
      if (currentUser.isThirdPartyLogin 
        && (!profile_saved || !profile_saved.firstName || !region_saved || !region_saved.id)) {
        this.router.navigate(['/profiles']);
        return false;
      } else {
        return true;
      }
  }
}