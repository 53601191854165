import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServicehelperService } from './servicehelper.service'
import { map } from 'rxjs/operators'
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeService {

  currentUser$: BehaviorSubject<any>;
  public user;
  public hostName = ServicehelperService.getBaseUrlHttps();
  constructor(public http: HttpClient, public router:Router) {
      // console.log('Hello AuthorizeServiceProvider Provider');
      this.currentUser$ = new BehaviorSubject(null);
  }

  Authorize(username: string, password: string) {
      // console.log("authorizing...", username);
      // console.log("authorizing...", password);

      if (username === null || password === null) {
          return Observable.throw("Please insert credentials");
      } else {
          return Observable.create(observer => {
              // At this point make a request to your backend to make a real check!
              this.authenticate(username, password).subscribe(resUser=>{
                this.user = resUser;

                localStorage.setItem('adminID', this.user.id);

                // console.log("authorized, user is: ", resUser);
                this.http.get(this.hostName + "/elementAdmin/api/purchaseOrderHelper.asp?CountryID=" + this.user.defaultCountryID + "&action=setCountry");
                this.currentUser$.next(resUser);
                observer.next(this.user);
                observer.complete();
              },err=> console.log(err));
          });
      }
  }

  AuthorizePostTrip(username:string, password:string, employeeId:number) {
    if (username === null || password === null || employeeId == null) {
      return Observable.throw("Please insert credentials");
    } else {
      return Observable.create(observer => {
        // At this point make a request to your backend to make a real check!
        this.authenticatePostTrip(username, password, employeeId).subscribe(resUser => {
          this.user = resUser;
          if (this.user.isActive) {
            localStorage.setItem('employeeId', this.user.employeeId);
            localStorage.setItem('email', this.user.email);

            // console.log("authorized, user is: ", resUser);
            // this.http.get(this.hostName + "/elementAdmin/api/purchaseOrderHelper.asp?CountryID=" + this.user.defaultCountryID + "&action=setCountry");
            this.currentUser$.next(resUser);
            observer.next(this.user);
            observer.complete();
          }
          else {
            observer.next({user: {loggedin: false}});
            observer.complete();
          }
        }, err => console.log(err));
      });
    }
  }

  public CheckEmployeeIdIsActive(employeeId: any): Promise<Observable<any>> {
      return Observable.create(observer => {
        // At this point make a request to your backend to make a real check!
        this.checkEmployeeStatus(employeeId).subscribe(resUser => {
          this.user = resUser;
          console.log(this.user);
          if (this.user.isActive) {
            // console.log("authorized, user is: ", resUser);
            // this.http.get(this.hostName + "/elementAdmin/api/purchaseOrderHelper.asp?CountryID=" + this.user.defaultCountryID + "&action=setCountry");
            this.currentUser$.next(resUser);
            observer.next(this.user);
            observer.complete();
          }
          else {
            // probably need some error at some point
          }
        }, err => console.log(err));
      });
  }

  public isAuthenticated(){
      return this.user;
  }

  get isLoggedIn(): boolean {

    return  this.isAuthenticated(); //localStorage.getItem('adminID') !== null ? true : false;

  }

  public getAdminID(){

    if(this.isAuthenticated)
      return this.user.id;
    else
      this.router.navigate(['/login']);


  }

  public getEmployeeID(){

    if(this.isAuthenticated)
      return this.user.employee.id;
    else
      this.router.navigate(['/login']);


  }


  private authenticate(username: string, password: string){
      let headers = new Headers({ 'Content-Type': 'application/json' }); // ... Set content type to JSON
      let isThirdPartyLogin = false;
      let type = 'default';
      if (environment.type === 'sandhauling') {
        type = 'sandhauling';
        if (
            (environment.isTesting && username.includes('sandhauler'))
            || (!environment.isTesting && !window.location.href.includes('element.ripplegroup.ca'))
          ) {
          isThirdPartyLogin = true;
        }
      }
      return this.http.post(this.hostName + '/api/authorize/', { username: username, password: password, isThirdPartyLogin, type }, { withCredentials:true }) // ...using post request
      .pipe(
        map((data: Response) => {
          return data;
        }, ( error => Observable.throw(error|| 'Server error'))
     ))
  }

  private authenticatePostTrip(username: string, password: string, employeeId: number) {
    let headers = new Headers({ 'Content-Type': 'application/json' }); // ... Set content type to JSON
    return this.http.post(this.hostName + '/api/posttripforms/', { username: username, password: password, employeeId: employeeId}) // ...using post request
      .pipe(
        map((data: Response) => {
          console.log(data);
          return data;
        }, (error => Observable.throw(error || 'Server error'))
        ))
  }

  private checkEmployeeStatus(employeeId: number) {
    let headers = new Headers({ 'Content-Type': 'application/json' }); // ... Set content type to JSON
    return this.http.get(this.hostName + '/api/posttripforms/checkemployeeidisactive/'  + employeeId ) // ...using post request
      .pipe(
        map((data: Response) => {
          console.log(data);
          return data;
        }, (error => Observable.throw(error || 'Server error'))
        ))
  }


  public getUserInfo() {
      return this.user;
  }

  public logout() {
      this.user = null;
      this.currentUser$.next(null);
  }

}
