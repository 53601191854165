import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import {AuthGuard} from './api/authguard.service';
import {ProfileGuard} from './api/profileguard.service';

const ASDayRatesRoutes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./asdayrates/home/home.module').then( m => m.HomePageModule),
    canActivate:[AuthGuard],
    canLoad:[AuthGuard]
  },

  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
   {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'detail',
    children:[

      {
        path:':id',
        loadChildren: () => import('./asdayrates/detail/detail.module').then( m => m.DetailPageModule),
        canActivate:[AuthGuard],
        canLoad:[AuthGuard]
      },
      {
        path:'',
        pathMatch: 'full',
        loadChildren: () => import('./asdayrates/detail/detail.module').then( m => m.DetailPageModule),
        canActivate:[AuthGuard],
        canLoad:[AuthGuard]
      }
    ]
  },
  {
    path: 'sheetrows',
    children:[

      {
        path:':id/:rowID',
        loadChildren: () => import('./asdayrates/sheetrows/sheetrows.module').then( m => m.SheetrowsPageModule),
        canActivate:[AuthGuard],
        canLoad:[AuthGuard]
      },
      {
        path:'',
        pathMatch: 'full',
        loadChildren: () => import('./asdayrates/sheetrows/sheetrows.module').then( m => m.SheetrowsPageModule),
        canActivate:[AuthGuard],
        canLoad:[AuthGuard]
      }
    ]
  }

];

const SandHaulingRoutes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./sandhauling/home/home.module').then( m => m.HomePageModule),
    canActivate:[AuthGuard, ProfileGuard],
    canLoad:[AuthGuard, ProfileGuard]
  },
  {
    path: 'profiles',
    loadChildren: () => import('./sandhauling/driverprofile/driverprofile.module').then( m => m.DriverprofilePageModule),
    canActivate:[AuthGuard],
    canLoad:[AuthGuard]
  },
  {
    path: 'dispatch',
    loadChildren: () => import('./sandhauling/dispatch-menu/dispatch-menu.module').then( m => m.DispatchMenuPageModule),
    canActivate:[AuthGuard, ProfileGuard],
    canLoad:[AuthGuard, ProfileGuard]
  },
  {
    path: 'driver',
    loadChildren: () => import('./sandhauling/driver-menu/driver-menu.module').then( m => m.DriverMenuPageModule),
    canActivate:[AuthGuard, ProfileGuard],
    canLoad:[AuthGuard, ProfileGuard]
  },
  {
    path: 'thirdPartyLoadDetails',
    loadChildren: () => import('./sandhauling/third-party-load-details/third-party-load-details.module').then( m => m.ThirdPartyLoadDetailsPageModule),
    canActivate:[AuthGuard],
    canLoad:[AuthGuard]
  },
  {
    path: 'elementdriver',
    loadChildren: () => import('./sandhauling/element-driver-menu/element-driver-menu.module').then( m => m.ElementDriverMenuPageModule),
    canActivate:[AuthGuard],
    canLoad:[AuthGuard]
  },
  {
    path: 'homeSand',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
   {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }

];

const PostTripFormsRoutes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./posttripform/home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard, ProfileGuard],
    canLoad: [AuthGuard, ProfileGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./posttripform/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'thankyou',
    loadChildren: () => import('./posttripform/thankyou/thankyou.module').then(m => m.ThankYouPageModule),
    canActivate: [AuthGuard, ProfileGuard],
    canLoad: [AuthGuard, ProfileGuard]
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

let routes = ASDayRatesRoutes;

if (environment.type === 'sandhauling') routes = SandHaulingRoutes;
else if (environment.type === 'posttripform') routes = PostTripFormsRoutes;
console.log(routes);


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
