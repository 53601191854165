import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class ServicehelperService  {
  //window.location.href.includes("localhost")
  static getBaseUrlHttp() {
    if (window.location.href.includes("localhost")) {
      return "http://localhost:33302";
    } 
    else if  (window.location.href.includes("ngrok")) {
      return "http://ebs.ngrok.io";
    }
    else {
      return "http://element.ripplegroup.ca";
    }
  }

  static getBaseUrlHttps() {
    if (window.location.href.includes("localhost")) {
      return "http://localhost:33302";
    } 
    else if  (window.location.href.includes("ngrok")) {
      return "https://ebs.ngrok.io";
    }else {
      return "https://element.ripplegroup.ca";
    }
  }

}
